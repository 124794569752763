@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'aos/dist/aos.css';

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: white;
}

#editor {
  margin-top: 65px;
}
.Mui-disabled {
  opacity: 0.5; /* Increase contrast */
  background-color: #e0e0e0; /* Light grey background */
  color: #a0a0a0; /* Dimmed text */
}

.pac-container {
  z-index: 9999 !important;
}
.editor-container .ck-editor__editable_inline {
  min-height: 300px; /* Adjust this value to your desired height */
  max-height: 500px; /* Optionally, set a maximum height */
}
